<template>
  <b-card
    :ref="`rec_product_card_ref_${product._id}`"
    class="ecommerce-card ecom product-card overflow-visible ecom-card-padding"
    no-body
    :class="{ 'no-hover': isWishlistDropdownOpened || isNoHoverProductCard }"
  >
    <span
      v-if="product.keyStyles"
      class="text-capitalize table-status key-style"
    >
      {{ product.keyStyles }}
    </span>
    <span
      v-if="PRODUCT_STATUS.CANCELLED.VALUE === product.status"
      class="product-status-tag cancelled"
    >{{ PRODUCT_STATUS.CANCELLED.LABEL }}</span>
    <div class="item-img text-center p-0 pb-0 position-realtive flex-column">
      <b-link @click="openProductDetail(product)">
        <div class="card-square-image d-block">
          <b-card-img-lazy
            class="bg-gray img img-responsive fullwidth"
            :alt="`${product.name}-${product._id}`"
            fluid-grow
            :src="useEcommerceUi().parseProductImage(product)"
            :class="`img-${product._id}`"
            @load.native="calculateImageRatio(`img-${product._id}`)"
            @error.native="imageLoadError"
          />
          <div class="product-color-wrapper">
            <div
              v-for="productColors in product.colors"
              :key="productColors.color"
              class="product-color-bg"
            >
              <div
                v-if="getColorCode(productColors.colorFamily)"
                v-b-tooltip.hover.left="productColors.color"
                class="product-color"
                :class="classForWhiteColor(productColors.colorFamily)"
                :style="'background:' + getColorCode(productColors.colorFamily)"
              />
            </div>
          </div>
        </div>
        <!-- Product Details -->

        <b-card-body class="p-0">
          <div class="px-50 py-25">
            <div class="product-name item-name d-flex">
              <b-link
                :id="`tooltip_product_name_${product._id}`"
                class="text-left"
                @click="openProductDetail(product)"
                @mouseover="
                  showTooltip(
                    `tooltip_product_name_${product._id}`,
                    product._id
                  )
                "
              >
                {{ product.name }}
              </b-link>
              <b-tooltip
                v-if="product._id === tooltipId"
                class="font-Inter"
                :target="`tooltip_product_name_${product._id}`"
                placement="bottom"
                triggers="hover"
              >
                {{ product.name }}
              </b-tooltip>
            </div>
          </div>
        </b-card-body>
      </b-link>
      <div
        :id="`tooltip_add_to_cart_btn_${product._id}`"
        class="item-options text-center shop-card-options"
      >
        <b-button
          v-if="isRetailer"
          variant="none"
          tag="a"
          class="btn-cart p-0 intro-add-cart button-cart"
          :disabled="inactiveCollection"
          @click="handleCartActionClick({ product })"
        >
          <feather-icon
            icon="ShoppingCartIcon"
            class="mr-50"
            size="18"
            :class="product.isInCart && 'cart-icon'"
          />
          <span>{{ product.isInCart ? 'In Cart' : 'Add' }}</span>
        </b-button>
        <product-wishlists-dropdown
          v-if="cardWidth"
          :product="product"
          :wishlists="wishlists"
          :disabled="inactiveCollection"
          :card-width="cardWidth"
          :is-selections="isBrand"
          :is-recommended-product="true"
          :is-opened-dropdown="isWishlistDropdownOpened"
          :product-wishlists-count="product.wishlists.length"
          @select-wishlist-on-product="
            $emit('select-wishlist-on-product', $event)
          "
          @toggle-wishlist-dropdown="$emit('toggle-wishlist-dropdown', $event)"
          @create-wishlist="$emit('create-wishlist')"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BLink,
  BCardBody,
  BTooltip,
  VBTooltip,
  BCardImgLazy,
  BButton,
} from 'bootstrap-vue'
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { isEllipsisActive } from '@/@core/utils/utils'
import ProductImageMixin from '@/views/mixins/ProductImageMixin.vue'

import { getColorCode, classForWhiteColor } from '@/product-colors'
import {
  productDetailsRouteName,
  productDetailsWithWishlistRouteName,
} from '@/router/routes/apps'
import ProductWishlistsDropdown from '@/views/components/product-wishlists-dropdown/ProductWishlistsDropdown.vue'
import { mapState } from 'vuex'
import UserRoleMixinVue from '../../UserRoleMixin.vue'
import { useEcommerceUi } from '../../useEcommerce'

const { formatCurrency } = utils

const { PRODUCT_STATUS } = c

export default {
  components: {
    BCard,
    BLink,
    BCardBody,
    BTooltip,
    BCardImgLazy,
    BButton,
    ProductWishlistsDropdown,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [ProductImageMixin, UserRoleMixinVue],
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    inactiveCollection: {
      type: Boolean,
      default: false,
    },
    wishlistId: {
      type: String,
      default: '',
    },
    wishlists: {
      type: Array,
      default: () => [],
    },
    isWishlistDropdownOpened: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'select-wishlist-on-product',
    'toggle-wishlist-dropdown',
    'create-wishlist',
  ],
  data() {
    return {
      formatCurrency,
      PRODUCT_STATUS,
      useEcommerceUi,
      getColorCode,
      classForWhiteColor,
      tooltipId: '',
      cardWidth: 0,
    }
  },
  computed: {
    ...mapState({
      isNoHoverProductCard: state => state.shop.isNoHoverProductCard,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.setCardWidth()
    }, 500)
    window.addEventListener('resize', this.setCardWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.setCardWidth)
  },
  methods: {
    setCardWidth() {
      this.cardWidth = this.$refs?.[`rec_product_card_ref_${this.product._id}`]?.offsetWidth
        || 0
    },
    openProductDetail(product) {
      let name = productDetailsRouteName
      const params = { id: product._id, collectionId: product.collectionId }
      if (this.wishlistId) {
        name = productDetailsWithWishlistRouteName
        params.wishlistId = this.wishlistId
      }
      const routeData = this.$router.resolve({
        name,
        params,
      })
      window.open(routeData.href, '_blank')
    },
    showTooltip(id, productId) {
      const productNameElement = document.getElementById(id)
      if (productNameElement && isEllipsisActive(productNameElement)) {
        this.tooltipId = productId
      }
    },
  },
  setup() {
    const { toggleProductInWishlist, handleCartActionClick } = useEcommerceUi()

    return {
      toggleProductInWishlist,
      handleCartActionClick,
    }
  },
}
</script>
